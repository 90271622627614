import React from 'react'
import { handleItemAdded, handleItemUpdated } from './src/utils/cart'
import LocaleProvider from './src/providers/LocaleProvider'
import CheckoutProvider from './src/providers/CheckoutProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './src/styles/global.css'

const toastOptions = {
    draggable: false,
    hideProgressBar: false,
    toastClassName:
        'bg-ocean-green-500 text-white text-center px-2 py-3 mx-auto max-w-sm w-full shadow-lg rounded-lg mt-5 lg:mt-10',
    closeButton: true,
    autoClose: 2000,
}

export const wrapRootElement = ({ element }) => {
    return (
        <LocaleProvider>
            <CheckoutProvider
                handleItemAdded={handleItemAdded}
                handleItemUpdated={handleItemUpdated}
            >
                {element}
                <ToastContainer {...toastOptions} limit={5} />
            </CheckoutProvider>
        </LocaleProvider>
    )
}
