import React, { useState } from 'react'
import LocaleContext from '../context/LocaleContext'
import { Locale } from '../domain/locale'

type Props = {
    children: React.ReactNode | React.ReactNode[]
}

function LocaleProvider({ children }: Props) {
    const [locale, setLocale] = useState(Locale.Global)

    return (
        <LocaleContext.Provider
            value={{
                locale,
                setLocale,
            }}
        >
            {children}
        </LocaleContext.Provider>
    )
}

export default LocaleProvider
