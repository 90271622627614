enum Locale {
    ID,
    SG,
    AU,
    Global,
}

interface ILocaleContext {
    locale: Locale
    setLocale: (value: Locale) => void
}

function localisePath(pathToLocalise: string, locale: Locale) {
    // Sanitise path by removing prefix slash if it's not "/",
    // and if it's a "/", set as empty string
    const sanitisedPath =
        pathToLocalise.length > 1
            ? pathToLocalise.substr(pathToLocalise.indexOf('/') + 1)
            : ''

    if (locale === Locale.AU) {
        return `/au/${sanitisedPath}`
    } else if (locale === Locale.SG) {
        return `/sg/${sanitisedPath}`
    } else if (locale === Locale.ID) {
        return `/id/${sanitisedPath}`
    } else if (locale === Locale.Global) {
        return `/${sanitisedPath}`
    } else {
        throw Error('Locale not set')
    }
}

function localiseMoney(amount: number, locale: Locale) {
    if (locale === Locale.AU) {
        return `A${new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
        }).format(amount)}`
    } else if (locale === Locale.SG) {
        return `S${new Intl.NumberFormat('en-SG', {
            style: 'currency',
            currency: 'SGD',
        }).format(amount)}`
    } else if (locale === Locale.ID) {
        return `${new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(amount)}`
    } else {
        return `Error: Choose a locale`
    }
}

export { Locale, ILocaleContext, localisePath, localiseMoney }
