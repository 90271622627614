import React, { useState, useEffect } from 'react'
import Shopify from 'shopify-buy'
import CheckoutContext from '../../context/CheckoutContext'
import { ICheckoutProviderProps, LineItem } from '../../domain/checkout'

const client = Shopify.buildClient({
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
})

function ShopifyProvider(props: ICheckoutProviderProps) {
    const { children, handleItemAdded, handleItemUpdated } = props

    const [available, setAvailable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [checkout, setCheckout] = useState<Shopify.Cart>(null)
    const checkoutIdStorageKey = process.env.GATSBY_SHOPIFY_CHECKOUT_ID_KEY

    // Initialise checkout
    useEffect(() => {
        async function initialiseCheckout() {
            // 1. Retrieve checkout ID from local storage.
            // 2. If it exists, fetch the checkout from Shopify
            // 3. Else (it does not exist), create a new checkout
            const checkoutId = localStorage.getItem(checkoutIdStorageKey)

            // Set loading to true as we will be performing async calls
            setLoading(true)

            // If checkout exists, fetch it. Else, create a new one
            if (!!checkoutId) {
                const newCheckout = await client.checkout.fetch(checkoutId)
                setCheckout(newCheckout)
                setAvailable(true)
            } else {
                const newCheckout = await client.checkout.create()
                setCheckout(newCheckout)
                setAvailable(true)

                // Save this checkout in local storage
                localStorage.setItem(
                    checkoutIdStorageKey,
                    newCheckout.id.toString()
                )
            }

            // Set loading to false when done
            setLoading(false)
        }
        initialiseCheckout()
    }, [])

    // Extract the web url for checkout if it is not null
    // @ts-ignore: TS definition outdated
    const checkoutUrl = !!checkout ? checkout.webUrl : ''

    // Transform Shopify's LineItem to our domain LineItem
    const lineItems: LineItem[] = !!checkout
        ? checkout.lineItems.map(lineItem => ({
            // @ts-ignore: TS definition outdated
            variantId: lineItem.variant.id,
            name: lineItem.title,
            // @ts-ignore: TS definition outdated
            price: parseFloat(lineItem.variant.priceV2.amount),
            quantity: lineItem.quantity,
            // @ts-ignore: TS definition outdated
            imageUrl: !!lineItem.variant.image.src
                ? // @ts-ignore: TS definition outdated
                lineItem.variant.image.src
                : null,
        }))
        : []

    const subtotalPriceStr = !!checkout ? checkout.subtotalPriceV2.amount : '0'
    const subtotalPrice = parseFloat(subtotalPriceStr)

    async function addLineItem(variantId: string, quantity: number) {
        if (!checkout) throw Error('Checkout is null')

        // Start loading for async call
        setLoading(true)

        // Create a new line item
        const lineItem: Shopify.LineItemToAdd = { variantId, quantity }

        // Push the new line item online
        const newCheckout = await client.checkout.addLineItems(checkout.id, [
            lineItem,
        ])

        // Save new checkout from previous operation
        setCheckout(newCheckout)

        // Don't forget to stop loading
        setLoading(false)

        // Execute callback
        handleItemAdded()
    }

    async function incrementLineItem(variantId: string) {
        if (!checkout) throw Error('Checkout is null')

        // Start loading for async call
        setLoading(true)

        // Get line item in cart
        const lineItemInCart = checkout.lineItems.find(
            // @ts-ignore: TS definition outdated
            item => item.variant.id === variantId
        )

        // Increment the line item
        const newLineItem: Shopify.AttributeInput = {
            id: lineItemInCart.id,
            variantId,
            quantity: lineItemInCart.quantity + 1,
        }

        // Update line item
        const newCheckout = await client.checkout.updateLineItems(checkout.id, [
            newLineItem,
        ])

        // Save new checkout from previous operation
        setCheckout(newCheckout)

        // Don't forget to stop loading
        setLoading(false)

        // Execute callback
        handleItemUpdated()
    }

    async function decrementLineItem(variantId: string) {
        if (!checkout) throw Error('Checkout is null')

        // Start loading for async call
        setLoading(true)

        // Get line item in cart
        const lineItemInCart = checkout.lineItems.find(
            // @ts-ignore: TS definition outdated
            item => item.variant.id === variantId
        )

        // Decrement the line item
        const newLineItem: Shopify.AttributeInput = {
            id: lineItemInCart.id,
            variantId,
            quantity: lineItemInCart.quantity - 1,
        }

        // Update line item
        const newCheckout = await client.checkout.updateLineItems(checkout.id, [
            newLineItem,
        ])

        // Save new checkout from previous operation
        setCheckout(newCheckout)

        // Don't forget to stop loading
        setLoading(false)

        // Execute callback
        handleItemUpdated()
    }

    async function removeLineItem(variantId: string) {
        if (!checkout) throw Error('Checkout is null')

        // Start loading for async call
        setLoading(true)

        // Get line item in cart as we need the line item ID, not variant ID
        const lineItemInCart = checkout.lineItems.find(
            // @ts-ignore: TS definition outdated
            item => item.variant.id === variantId
        )

        // If line item does not exist, we can stop here (remember to stop loading)
        if (!lineItemInCart) {
            setLoading(false)
            return
        }

        // Remove the line item
        const newCheckout = await client.checkout.removeLineItems(checkout.id, [
            lineItemInCart.id.toString(),
        ])

        // Save new checkout from previous operation
        setCheckout(newCheckout)

        // Don't forget to stop loading
        setLoading(false)
    }

    return (
        <CheckoutContext.Provider
            value={{
                available,
                loading,
                checkoutUrl,
                lineItems,
                subtotalPrice,
                addLineItem,
                incrementLineItem,
                decrementLineItem,
                removeLineItem,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    )
}

export default ShopifyProvider
