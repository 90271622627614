import React, { useContext, Fragment } from 'react'
import LocaleContext from '../context/LocaleContext'
import { Locale } from '../domain/locale'
import { ICheckoutProviderProps } from '../domain/checkout'
import ShopifyProvider from './checkout/ShopifyProvider'

function CheckoutProvider(props: ICheckoutProviderProps) {
    const { locale } = useContext(LocaleContext)
    const { children } = props

    if (locale === Locale.AU) {
        return <ShopifyProvider {...props}>{children}</ShopifyProvider>
    } else {
        return <Fragment>{children}</Fragment>
    }
}

export default CheckoutProvider
