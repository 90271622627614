import React from 'react'
import { ICheckoutState } from '../domain/checkout'

const initialState: ICheckoutState = {
    available: false,
    loading: false,
    checkoutUrl: '',
    lineItems: [],
    subtotalPrice: 0,
    addLineItem: (variantId: string, quantity: number) => {},
    incrementLineItem: (variantId: string) => {},
    decrementLineItem: (variantId: string) => {},
    removeLineItem: (variantId: string) => {},
}

const CheckoutContext = React.createContext(initialState)

export default CheckoutContext
