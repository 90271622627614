import React from 'react'
import { ILocaleContext, Locale } from '../domain/locale'

const ctx: ILocaleContext = {
    locale: Locale.Global,
    setLocale: () => {},
}

const LocaleContext = React.createContext(ctx)

export default LocaleContext
