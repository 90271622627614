// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-au-cart-tsx": () => import("./../../../src/pages/au/cart.tsx" /* webpackChunkName: "component---src-pages-au-cart-tsx" */),
  "component---src-pages-au-contact-us-tsx": () => import("./../../../src/pages/au/contact-us.tsx" /* webpackChunkName: "component---src-pages-au-contact-us-tsx" */),
  "component---src-pages-au-index-tsx": () => import("./../../../src/pages/au/index.tsx" /* webpackChunkName: "component---src-pages-au-index-tsx" */),
  "component---src-pages-au-products-tsx": () => import("./../../../src/pages/au/products.tsx" /* webpackChunkName: "component---src-pages-au-products-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-id-cart-tsx": () => import("./../../../src/pages/id/cart.tsx" /* webpackChunkName: "component---src-pages-id-cart-tsx" */),
  "component---src-pages-id-contact-us-tsx": () => import("./../../../src/pages/id/contact-us.tsx" /* webpackChunkName: "component---src-pages-id-contact-us-tsx" */),
  "component---src-pages-id-index-tsx": () => import("./../../../src/pages/id/index.tsx" /* webpackChunkName: "component---src-pages-id-index-tsx" */),
  "component---src-pages-id-products-tsx": () => import("./../../../src/pages/id/products.tsx" /* webpackChunkName: "component---src-pages-id-products-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-sg-cart-tsx": () => import("./../../../src/pages/sg/cart.tsx" /* webpackChunkName: "component---src-pages-sg-cart-tsx" */),
  "component---src-pages-sg-contact-us-tsx": () => import("./../../../src/pages/sg/contact-us.tsx" /* webpackChunkName: "component---src-pages-sg-contact-us-tsx" */),
  "component---src-pages-sg-index-tsx": () => import("./../../../src/pages/sg/index.tsx" /* webpackChunkName: "component---src-pages-sg-index-tsx" */),
  "component---src-pages-sg-products-tsx": () => import("./../../../src/pages/sg/products.tsx" /* webpackChunkName: "component---src-pages-sg-products-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-templates-au-product-page-tsx": () => import("./../../../src/templates/au/ProductPage.tsx" /* webpackChunkName: "component---src-templates-au-product-page-tsx" */)
}

